<!--  -->
<template>
  <div class="box" v-loading="loading" style="height:auto;padding:5px; box-sizing: border-box;">
    <div class="container" style="height: 100%">
      <el-tabs tab-position="left" v-model="tabPosition" style="height: 100%">
        <el-tab-pane
          v-for="(item, i) in Rolelist"
          :name="'_id' + item.useragentroleid" 
          :key="i"
          style="height: 100%;overflow-y: auto; "
        >
          <span slot="label"><b>{{ item.ServiceProvidName }}</b> ({{ item.rolename }})</span>
           <el-row  style="margin-top: 10px; ">
             <el-col :span="11"
              v-for="(items, i) in item.UUSystems"
              :key="i"
               style="margin-bottom: 10px;margin-right:10px"
            >
              <button
                class="login_button"
                @click="toSysUrl(item.useragentroleid, items.SysUrl,items.SysCode)"
              >
                <span> {{ items.SysName }}</span> 
              </button>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { GetCurUUSystems } from "@/network/api";
import {
  ChangeUserRole,
  GetUserRolesAndSystem,
  GetUserInfo,
  querystatus,
  UpdateUserLastSysCode
} from "@/network/api";
export default {
  data() {
    return {
      list: [],
      Logo: "",
      MaxTitle: "",
      sysList: [],
      Roles: [],
      Rolelist: [],
      UserInfo: {},
      loading: true,
      tabPosition: "",
    };
  },

  components: {},

  computed: {},

  async mounted() {
    this.loading = await true;
    await querystatus();
    // await GetCurUUSystems().then((res) => {
    //   if (res.code == 1) {
    //     this.list = res.data.rows;
    //   } else {
    //     this.$message({ message: res.msg, type: "error" });
    //   }
    // });
    await GetUserRolesAndSystem().then((res) => {
      if (res.code == 1) {
        this.Rolelist = res.data;
      } else if (res.code == 102 || res.code == 103) {
        this.$message({
          message: res.msg,
          center: true,
          type: "error",
          duration: 1000,
          onClose: () => {
            window.location.replace(res.data);
          },
        });
      } else {
        this.$message({ message: res.msg, type: "error" });
      }
    });
    await GetUserInfo().then((res) => {
      if (res.code == 1) {
        this.UserInfo = res.data;
        let obj = this.Rolelist.filter(
          (item) =>
            item.roleid == res.data.roleid &&
            item.userid == res.data.userid &&
            item.serviceproviderid == res.data.serviceproviderid
        );
        this.tabPosition =
          "_id" + (obj && obj[0] ? obj[0].useragentroleid : "");
        // GetUserRoles({ userid: res.data.userid }).then((res2) => {
        //   if (res2.code == 1) {
        //     this.Roles = res2.data;
        //     this.Roles.unshift({
        //       useragentroleid: this.UserInfo.roleid,
        //       ServiceProvidName: this.UserInfo.ServiceName,
        //       rolename: this.UserInfo.rolename,
        //     });
        //   } else {
        //     this.$message({
        //       showClose: true,
        //       message: res2.msg,
        //       type: "error",
        //     });
        //   }
        // });
      }
    });
    this.loading = await false;
  },

  methods: {
    toSysUrl(id, url,SysCode) {
       UpdateUserLastSysCode({LastLoginForuseragentroleid:id,LastLoginSysCode:SysCode}).then(()=>{
        return ChangeUserRole({ useragentroleid: id, SysCode });
      }).then((res) => {
        if (res.code == 1) {
          top.location.replace(url);
        } else if (res.code == 102 || res.code == 103) {
          this.$message({
            message: res.msg,
            center: true,
            type: "error",
            duration: 1000,
            onClose: () => {
              top.location.replace(res.data);
            },
          });
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
.el-tabs--left,
.el-tabs--right,.el-tabs__content,.el-tab-pane {
  height: 100%;
}
/deep/.el-tabs__content {
     height: 100% !important;
}
.clearfix {
  padding: 10px;
  font-size: 18px;
  margin: auto;
  text-align: center;
  background-color: #1c87db;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.clearfix:hover {
  opacity: 0.8;
}
.container {
  width: 100vw;
  max-width: 1100px;
  height: 100vh;
  margin: auto;
  position: relative;
}
</style>